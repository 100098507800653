@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'PoppinsBold';
    src: url('../assets/Fonts/Poppins-Bold.ttf');
  }
  @font-face {
    font-family: 'PoppinsBlack';
    src: url('../assets/Fonts/Poppins-Black.ttf');
  }

  @font-face {
    font-family: 'PoppinsLight';
    src: url('../assets/Fonts/Poppins-Light.ttf');
  }

  @font-face {
    font-family: 'PoppinsItalic';
    src: url('../assets/Fonts/Poppins-LightItalic.ttf');
  }

  @font-face {
    font-family: 'PoppinsRegular';
    src: url('../assets/Fonts/Poppins-Regular.ttf');
  }

  @font-face {
    font-family: 'Rakkas';
    src: url('../assets/Fonts/Rakkas-Regular.ttf');
  }

  @font-face {
    font-family: 'Agbalumo';
    src: url('../assets/Fonts/Agbalumo-Regular.ttf');
  }
}

p,
h4,
h5,
h6,
span,
button {
  font-family: 'PoppinsRegular', sans-serif;
}

h1,
h2,
h3 {
  font-family: 'PoppinsBold', sans-serif;
  /* font-weight: 300; */
}

h2 {
  @apply text-4xl md:text-5xl;
}

h3 {
  @apply text-2xl md:text-3xl;
}

li {
  list-style: none;
}
